import { BoxGroup, Col, Row } from '@akiunlocks/perseus-ui-components';

import './DefaultContent.scss';

const ROWS = 10;

function DefaultContent() {
  return (
    <>
      <BoxGroup className="loader" boxCount={1} minHeight={3} />

      <div className="m-4">
        <BoxGroup className="loader" boxCount={1} minHeight={10} />
        <BoxGroup className="loader" boxCount={3} direction="column" marginBottom={0.5} minHeight={0.5} />

        {[...Array(ROWS)].map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Row key={index}>
            <Col xs={4} className="pe-0">
              <BoxGroup className="loader" boxCount={1} minHeight={5} />
            </Col>
            <Col>
              <BoxGroup className="loader" boxCount={4} direction="column" marginBottom={0.5} minHeight={0.5} />
            </Col>
          </Row>
        ))}
      </div>
    </>
  );
}

export default DefaultContent;
