import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { PrismaPreview } from '@akiunlocks/perseus-ui-components';

import { PRISMA_JS } from 'lib/constants/constants';
import { fetchJson } from 'lib/utils/requests';

import { HomeContext } from 'containers/Home/HomeProvider';
import { getVariantPreviewUrl, notifyHotspotClicked, notifyCloseClicked, replaceClickPlaceholders } from './utilities';
import QrPreviewModal from './QrPreviewModal';

function VariantRichMedia({ loading, maxWidth = undefined, setLoading, variant }) {
  const { data, hashId, isInterstitial, interstitialSize, isLandscape } = useContext(HomeContext);
  const [prismaJson, setPrismaJson] = useState(null);

  const [qrValue, setQrValue] = useState('');
  const [showQrModal, setShowQrModal] = useState(false);

  const useInterstitialSize = isInterstitial && interstitialSize;

  useEffect(() => {
    async function getPrismaJson() {
      const json = await fetchJson(variant.url);
      setPrismaJson(replaceClickPlaceholders(json, data.hotspots));
      setLoading(false);
    }
    if (variant.url) {
      getPrismaJson();
    }
  }, [variant.url]);

  // get base url from media url
  const baseUrl = variant.url.substring(0, variant.url.lastIndexOf('/') + 1);
  if (loading) {
    return null;
  }

  const prismaConfig = {
    clickCb: clickPlaceholder => notifyHotspotClicked(data.hotspots, clickPlaceholder),
    onClose: notifyCloseClicked,
  };

  const buttonsConfig = {};

  if (useInterstitialSize) {
    prismaConfig.isLandscape = isLandscape;
    // rotate preview if landscape is set
    prismaConfig.responsive = isLandscape
      ? { height: interstitialSize.width, width: interstitialSize.height }
      : interstitialSize;

    buttonsConfig.qr = {
      show: true,
      callback: () => {
        const previewUrl = getVariantPreviewUrl(hashId, variant.variantId);
        setQrValue(previewUrl);
        setShowQrModal(true);
      },
    };
  }

  return (
    <>
      <PrismaPreview
        baseUrl={baseUrl}
        buttonsConfig={buttonsConfig}
        canvasId={variant.variantId.toString()}
        deviceButtonsConfig={{
          value: interstitialSize,
        }}
        buttonsVariant="outline-primary"
        fileName={variant.variantName}
        prismaProject={prismaJson}
        prismaUrl={`${baseUrl}${PRISMA_JS}`}
        maxWidth={useInterstitialSize ? undefined : maxWidth}
        prismaConfig={prismaConfig}
      />
      {useInterstitialSize && (
        <QrPreviewModal show={showQrModal} onHide={() => setShowQrModal(false)} value={qrValue} />
      )}
    </>
  );
}

VariantRichMedia.propTypes = {
  loading: PropTypes.bool.isRequired,
  maxWidth: PropTypes.number,
  setLoading: PropTypes.func.isRequired,
  variant: PropTypes.shape({
    variantId: PropTypes.number.isRequired,
    variantName: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default VariantRichMedia;
