import PropTypes from 'prop-types';
import QRCode from 'react-qr-code';

import { Col, Modal, Row } from '@akiunlocks/perseus-ui-components';

function QrPreviewModal({ onHide, show, value }) {
  return (
    <Modal show={show} onHide={onHide} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Preview creative on your device</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="text-center my-4">
            <QRCode value={value} />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

QrPreviewModal.propTypes = {
  /** Do something when modal close is triggered */
  onHide: PropTypes.func.isRequired,
  /** Whether the modal will be displayed or not */
  show: PropTypes.bool.isRequired,
  /** QR code value */
  value: PropTypes.string.isRequired,
};

export default QrPreviewModal;
