import { createContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { FETCH_STATUS } from 'lib/constants/constants';
import { fetchDataWithStatus } from 'containers/utilities';

const PreviewContext = createContext();

function PreviewProvider({ children }) {
  const { hashId, variantId } = useParams();

  const [data, setData] = useState({});
  const [status, setStatus] = useState(FETCH_STATUS.LOADING);
  const [variant, setVariant] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const json = await fetchDataWithStatus(hashId, setStatus);
      if (!json) {
        return;
      }
      setData(json);
      const variant = json.variants.find(v => v.variantId.toString() === variantId);
      setVariant(variant || {});
    };
    fetchData();
  }, []);

  const contextValue = useMemo(
    () => ({
      data,
      hashId,
      status,
      variant,
    }),
    [data, hashId, status, variant],
  );

  return <PreviewContext.Provider value={contextValue}>{children}</PreviewContext.Provider>;
}

PreviewProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { PreviewContext, PreviewProvider };
