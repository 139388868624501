/**
 * Gets the current screen size.
 * @return {object} An object containing the width and height of the screen in pixels.
 */
export function getScreenSize() {
  return {
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  };
}
