import { notify } from '@akiunlocks/perseus-ui-components';

import { getLocationBaseUrl } from 'lib/utils/location';

/**
 * Sends a notification about a click on a hotspot's layer
 * @param {Array} hotspots - an array of hotspot objects
 * @param {String} clickPlaceholder - the placeholder string that was clicked
 * @returns {undefined}
 */
export const notifyHotspotClicked = (hotspots, clickPlaceholder) => {
  const hotspot = hotspots.find(hotspot => hotspot.clickPlaceholder === clickPlaceholder);
  if (hotspot) {
    const message = `Hotspot '${hotspot.name}' clicked!`;
    notify(hotspot.testUrl ? `${message} ${hotspot.testUrl}` : message);
  }
};

/**
 * Sends a notification when the close button is clicked
 * @returns {undefined}
 */
export const notifyCloseClicked = () => {
  notify('Close button clicked!');
};

/**
 * Replaces the placeholder strings in the JSON with the actual clickPlaceholder
 * @param {Object} json - the JSON object to be modified
 * @param {Array} hotspots - an array of hotspot objects
 * @returns {Object} - the modified JSON object
 */
export function replaceClickPlaceholders(json, hotspots) {
  if (hotspots?.length === 0) {
    return json;
  }
  let stringified = JSON.stringify(json);
  hotspots.forEach(hotspot => {
    stringified = stringified.replace(
      `perseus_placeholder_click_id_${hotspot.clickPlaceholder}`,
      hotspot.clickPlaceholder,
    );
  });

  return JSON.parse(stringified);
}

/**
 * Gets preview URL for one variant.
 * @param {string} compositeHashId - Composite hash id.
 * @param {string} variantId - Variant id.
 * @return {string} Variant preview URL.
 */
export function getVariantPreviewUrl(compositeHashId, variantId) {
  const locationBaseUrl = getLocationBaseUrl();
  return `${locationBaseUrl}/preview/${compositeHashId}/${variantId}`;
}
