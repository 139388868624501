import { useContext, useEffect, useState } from 'react';
import classnames from 'classnames';

import { PrismaPreview } from '@akiunlocks/perseus-ui-components';

import { PRISMA_JS } from 'lib/constants/constants';
import { debounce } from 'lib/utils/debounce';
import { fetchJson } from 'lib/utils/requests';

import { notifyHotspotClicked, replaceClickPlaceholders } from 'components/Content/Variant/utilities';

import { PreviewContext } from 'containers/Preview/PreviewProvider';
import { getScreenSize } from './utilities';
import DefaultContent from './DefaultContent';

import './PreviewContent.scss';

function PreviewContent() {
  const { data, variant } = useContext(PreviewContext);

  const [closed, setClosed] = useState(false);
  const [engine, setEngine] = useState(null);
  const [loadingJson, setLoadingJson] = useState(true);
  const [loadingPreview, setLoadingPreview] = useState(true);
  const [prismaJson, setPrismaJson] = useState(null);
  const [screenSize, setScreenSize] = useState(getScreenSize());

  useEffect(() => {
    async function getPrismaJson() {
      const json = await fetchJson(variant.url);
      setPrismaJson(replaceClickPlaceholders(json, data.hotspots));
      setLoadingJson(false);
    }
    if (variant.url) {
      getPrismaJson();
    }
  }, [variant.url]);

  useEffect(() => {
    const handleResize = debounce(() => {
      setScreenSize(getScreenSize());
    }, 100);

    // window resize event, will be used for orientation change
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    engine?.play();
  }, [engine]);

  // get base url from media url
  const baseUrl = variant.url?.substring(0, variant.url.lastIndexOf('/') + 1);

  const prismaConfig = {
    clickCb: clickPlaceholder => notifyHotspotClicked(data.hotspots, clickPlaceholder),
    onClose: () => setClosed(true),
    responsive: screenSize,
    isLandscape: screenSize.width > screenSize.height,
  };

  return (
    <>
      {(loadingJson || loadingPreview || closed) && <DefaultContent />}
      {!loadingJson && !closed && (
        <PrismaPreview
          baseUrl={baseUrl}
          buttonsConfig={{ show: false }}
          canvasId={variant.variantId.toString()}
          className={classnames('preview', { 'hide-preview': loadingPreview })}
          onEngineLoaded={engine => {
            setEngine(engine);
            setLoadingPreview(false);
          }}
          prismaConfig={prismaConfig}
          prismaProject={prismaJson}
          prismaUrl={`${baseUrl}${PRISMA_JS}`}
          showLoader={false}
        />
      )}
    </>
  );
}

export default PreviewContent;
