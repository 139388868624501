import { createContext, useState, useEffect, useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { usePage } from 'containers/Page/PageContext';
import { FETCH_STATUS, INTERSTITIAL_BANNER } from 'lib/constants/constants';
import { DEFAULT_SIZE } from 'lib/constants/interstitials';

import { fetchDataWithStatus } from 'containers/utilities';

import { applyFilters, loadFilters, serializeFilters, updateFiltersState } from './utilities';
import { INITIAL_PAGINATION_STATE, PAGINATION_ACTIONS, paginationReducer } from './pagination';

const HomeContext = createContext();

function HomeProvider({ children }) {
  const { hashId } = useParams();
  const [data, setData] = useState({});
  const [status, setStatus] = useState(FETCH_STATUS.LOADING);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useReducer(paginationReducer, INITIAL_PAGINATION_STATE);
  const [isInterstitial, setIsInterstitial] = useState(false);
  const [interstitialSize, setInterstitialSize] = useState(null);
  const [isLandscape, setIsLandscape] = useState(false);
  const [numColumns, setNumColumns] = useState(0); // 0 is the value for auto
  const { searchParams, setSearchParams } = usePage();

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      const json = await fetchDataWithStatus(hashId, setStatus);
      if (!json) {
        return;
      }
      setData(json);
      const isInterstitial = json?.productSubtype === INTERSTITIAL_BANNER;
      if (isInterstitial) {
        setIsInterstitial(isInterstitial);
        setInterstitialSize(DEFAULT_SIZE);
      }
      let newFilters = loadFilters(json);
      newFilters = updateFiltersState(searchParams, newFilters);
      setFilters(newFilters);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setPagination({ type: PAGINATION_ACTIONS.SET_ALL_ITEMS, payload: applyFilters(data?.variants, filters) });
  }, [filters]);

  const onFilterChange = (filterKey, filterValue, checked) => {
    const newFilters = { ...filters };
    newFilters[filterKey].find(option => option.urlValue === filterValue.urlValue).checked = checked;
    setSearchParams(serializeFilters(newFilters));
    setFilters(newFilters);
  };

  const onFilterAllChange = (filterKey, checked) => {
    const newFilters = { ...filters };
    newFilters[filterKey].forEach(filterValue => {
      const value = filterValue;
      value.checked = checked;
    });
    setSearchParams(serializeFilters(newFilters, true));
    setFilters(newFilters);
  };

  const pagedItems = useMemo(() => {
    const { items, page, pageSize } = pagination;
    const start = page * pageSize;
    const end = start + pageSize;
    return items.slice(start, end);
  }, [pagination.page, pagination.pageSize, pagination.items]);

  const contextValue = useMemo(
    () => ({
      hashId,
      pagedItems,
      filters,
      data,
      status,
      pagination,
      type: data.type,
      isInterstitial,
      interstitialSize,
      isLandscape,
      numColumns,
      onFilterChange,
      onFilterAllChange,
      setPagination,
      setInterstitialSize,
      setIsLandscape,
      setNumColumns,
    }),
    [pagination, filters, status, interstitialSize, isLandscape, numColumns],
  );

  return <HomeContext.Provider value={contextValue}>{children}</HomeContext.Provider>;
}

HomeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { HomeContext, HomeProvider };
