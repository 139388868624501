import { FETCH_STATUS } from 'lib/constants/constants';
import { fetchJson } from 'lib/utils/requests';

/**
 * Fetches composite data with a status update.
 * @param {string} hashId - Composite hash id.
 * @param {function} setStatus - Optional function to update the status.
 * @return {object} The fetched JSON data, or undefined if an error occurred.
 */
export async function fetchDataWithStatus(hashId, setStatus = () => {}) {
  let json;
  try {
    setStatus(FETCH_STATUS.LOADING);
    json = await fetchJson(`${process.env.REACT_APP_SAMPLES_URL}/${hashId}/data.json?t=${Date.now()}`);
    setStatus(FETCH_STATUS.SUCCESS);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    setStatus(FETCH_STATUS.ERROR);
  }
  return json;
}
