/**
 * Creates a debounced function which will be called after the specified delay.
 * @param {function} func - The function to debounce.
 * @param {number} delay - The number of milliseconds to delay.
 * @return {function} - A new debounced function.
 */
export function debounce(func, delay) {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
}
