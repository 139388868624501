import PreviewContent from 'components/PreviewContent/PreviewContent';

import { PreviewProvider } from 'containers/Preview/PreviewProvider';

function Preview() {
  return (
    <PreviewProvider>
      <PreviewContent />
    </PreviewProvider>
  );
}
export default Preview;
